// admin/src/Component/SpotActivator/Create.js

import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    regex,
    NumberInput
} from 'react-admin';

const validateEmail = regex(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

const postDefaultValue = {
    spot_activator_type: '/api/spot-activator-types/a8ba4f37-b1ce-4fa4-86ed-9e998cb3bef4',
};

export const SpotActivatorCreate = (props) => {

    return (
    <Create title="Add SpotActivator" { ...props }>
        <SimpleForm redirect="list" initialValues={postDefaultValue}>

            <TextInput source="name" label="Name" validate={ required() } />

            <ReferenceInput label="SpotActivator Type" source="spot_activator_type" reference="spot-activator-types" sort={{ field: 'name', order: 'ASC' }} >
                <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput  perPage={1000} label="MBS flight" source="mbs_flight" reference="mbs-flights" validate={ required() } filterToQuery={views => ({ name: views })}>
                <AutocompleteInput optionText="name" translateChoice={false} />
            </ReferenceInput>

            <NumberInput source="price_per_grp" label="Price per GRP" step={1}  />

            <TextInput source="email" label="E-mail TV planner" validate={ validateEmail }  />


        </SimpleForm>
    </Create>
    );
};