// in src/SpotAnalyzer/RefreshSpotAnlayzer.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import config from 'react-global-configuration';

class RefreshSpotAnalyzer extends Component {

    handleClick = () => {

        const webUrl = config.get('app.web_url');
        const {record } = this.props;
        window.open(`${webUrl}spot-analyzer/${record.originId}/refresh`, '_blank');
    }

    render() {

        return <Button variant="text" color="primary" size="small" onClick={this.handleClick}>
        <DeleteIcon /> &nbsp; Refresh
        </Button>;

    }
}

RefreshSpotAnalyzer.propTypes = {
    record: PropTypes.object,
};

export default connect(null, null)(RefreshSpotAnalyzer);