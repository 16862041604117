// in src/Components/Pixelplan/DownloadFeedButton.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import config from 'react-global-configuration';

class DownloadFeedButton extends Component {

    handleClick = () => {
        const webUrl = config.get('app.web_url');
        const feedUrl = config.get('app.feed_url');
        const {record} = this.props;

        window.open(`${webUrl}${feedUrl}${record.output}`, '_blank');
    }

    render() {
        return <Button variant="text" color="primary" size="small" onClick={this.handleClick}>
        <VisibilityIcon /> &nbsp; Download
        </Button>;
    }
}

DownloadFeedButton.propTypes = {
    record: PropTypes.object,
};

export default connect(null, null)(DownloadFeedButton);