// admin/src/Component/EnhancedConversion/Create.js

import React from 'react';
import {
    regex,
    Create,
    SimpleForm,
    TextInput,
    required,
    BooleanInput,
    ReferenceInput,
    SelectInput
} from 'react-admin';

const validateUrl = regex(/^(ftp|http|https):\/\/[^ "]+$/, 'Must be a valid url');
const requiredUrl = [required(), validateUrl];

const consentDefaultValue = {
    cookie_consent_same_site: '/api/cookie-consent-same-sites/08fc2129-d301-4b6a-a7bb-22a3f7a65897',
}

export const EnhancedConversionCreate = (props) => {

    return (
    <Create title="Add enhanced conversion " { ...props }>
        <SimpleForm redirect="list" initialValues={consentDefaultValue}>

            <BooleanInput source="active" label="Active" defaultValue={true} />
            <TextInput source="name" label="Name" validate={ required() } />
            <TextInput source="url" label="URL" validate={ requiredUrl } />
            <TextInput source="data_layer" label="Data layer" validate={ required() } defaultValue="dataLayer" />

            <ReferenceInput label="SameSite" source="cookie_consent_same_site" reference="cookie-consent-same-sites" validate={ required() }>
                <SelectInput optionText="name" />
            </ReferenceInput>

        </SimpleForm>
    </Create>
    );
};