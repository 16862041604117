import React from 'react';

import {
    Edit,
    TabbedForm,
    FormTab,
    TextInput,
    BooleanInput,
    required,
    regex,
    ReferenceInput,
    SelectInput,
    ReferenceManyField,
    Datagrid,
    DateField,
    TextField,
    NumberInput,
    BooleanField,
    EditButton,
    Button,
    Pagination
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { ColorInput } from 'react-admin-color-input';
import CardActions from '@material-ui/core/CardActions';
import LanguageIcon from "@material-ui/icons/Language";
import { Link } from 'react-router-dom';

const AddNewCookieConsentCookieButton = ({ page }) => {

    if(typeof(page) !== 'undefined') {

        return (
            <Button
                component={Link}
                to={{
                    pathname: '/cookie-consent-cookies/create',
                    search: `?cookieConsent=${page.id}`
                }}
                label="Add cookie"
            >
                <LanguageIcon/>
            </Button>
        );
    }

    return null;
}

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const CookieConsentCookieShowActions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <AddNewCookieConsentCookieButton page={data} />
    </CardActions>
);

const validateDomain = regex(/^[a-zA-Z0-9](?:[a-zA-Z0-9-.]{0,61}[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/, 'Must be a valid domain. Do not add www or http prefix. Subdomains should be converted to main domain.');
const requiredDomain = [required(), validateDomain];

export const CookieConsentEdit = (props) => (
    <Edit {...props} actions={<CookieConsentCookieShowActions />}>

        <TabbedForm>

            <FormTab label="Settings">

                <TextField source="id" />

                <TextInput source="name" label="Name" validate={ required() } />
                <TextInput source="url" label="URL" validate={ requiredDomain } />
                <BooleanInput source="active" label="Active" />
                <BooleanInput source="logging" label="Consent logging" />
                <BooleanInput source="more_cookie_information_enabled" label="Enable more cookie information" />
                <BooleanInput source="analytics_enabled" label="Enable Analytics cookies" />
                <BooleanInput source="functional_enabled" label="Enable Functional cookies" />
                <BooleanInput source="marketing_enabled" label="Enable Marketing cookies" />

                <BooleanInput source="analytics_default_checked" label="Analytics default checked" />
                <BooleanInput source="functional_default_checked" label="Functional default checked" />
                <BooleanInput source="marketing_default_checked" label="Marketing default checked" />
                <BooleanInput source="show_cookie_description" label="Show cookie description" />
                <BooleanInput source="backdrop_enabled" label="Backdrop enabled" />

                <ReferenceInput label="Template" source="cookie_consent_template" reference="cookie-consent-templates" validate={ required() }>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <ReferenceInput label="Language" source="cookie_consent_language" reference="cookie-consent-languages" validate={ required() }>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <ReferenceInput label="Position" source="cookie_consent_position" reference="cookie-consent-positions" validate={ required() }>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <ReferenceInput label="SameSite" source="cookie_consent_same_site" reference="cookie-consent-same-sites" validate={ required() }>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <TextInput source="data_layer" label="Data layer name" validate={ required() } />
                <TextInput multiline source="alternate_domains" label="Alternate url's" />

            </FormTab>

            <FormTab label="Consent mode">

                <BooleanInput source="google_consent_mode_enabled" label="Google Consent Mode" />
                <BooleanInput source="microsoft_consent_mode_enabled" label="Microsoft Consent Mode" />
                <BooleanInput source="enforce_gtag" label="Enforce gtag" />
                <BooleanInput source="default_security_storage_granted" label="Default security storage granted" />
                <BooleanInput source="default_personalization_storage_granted" label="Default personalization granted" />
                <BooleanInput source="default_preferences_storage_granted" label="Default preferences storage granted" />
                <BooleanInput source="default_analytics_storage_granted" label="Default analytics storage granted" />
                <BooleanInput source="default_ad_storage_granted" label="Default ad storage granted" />
                <BooleanInput source="default_ad_user_data_granted" label="Default ad user data granted" />
                <BooleanInput source="default_ad_personalization_granted" label="Default ad personalization granted" />

            </FormTab>

            <FormTab label="Layout">

                <ColorInput source="color" label="Color backdrop" />

                <ColorInput source="button1_color" label="Allow all button color" />
                <ColorInput source="button2_color" label="Cookie settings button color" />
                <ColorInput source="button3_color" label="Save settings button color" />
                <ColorInput source="button4_color" label="Reject button color" />
                <ColorInput source="pill_color" label="Pill color" />

                <BooleanInput source="hidden_horizontal_rule" label="Hide horizontal rule" />

                <TextInput source="font" label="Font" />

                <SelectInput source="title_transform" allowEmpty={true} choices={[
                    { id: 'none', name: 'None' },
                    { id: 'capitalize', name: 'Capitalize' },
                    { id: 'uppercase', name: 'Uppercase' },
                    { id: 'lowercase', name: 'Lowercase' },
                ]} />

                <NumberInput source="header_size" label="Header font size" step={1} />
                <NumberInput source="subheader_size" label="Subheader font size" step={1}  />
                <NumberInput source="body_size" label="Body font size" step={1} />
                <NumberInput source="always_active_size" label="Always active font size" step={1} />
                <NumberInput source="consent_height" label="Consent height" step={1} />

                <TextInput multiline source="inline_css" label="Inline css" fullWidth={true} />

            </FormTab>

            <FormTab label="Descriptions">
                <TextInput source="consent_title" label="Consent title" validate={ required() } />
                <RichTextInput {...props} source="consent_message" toolbar={[ ['bold', 'italic', 'underline', 'link', 'image', 'video', 'clean'],[{ 'header': [1, 2, 3, 4, 5, 6, false] }] ]} />
                <TextInput source="preferences_title" label="Preferences Title" validate={ required() }  defaultValue="Cookie instellingen aanpassen"  />
                <RichTextInput {...props} source="preferences_message" toolbar={[ ['bold', 'italic', 'underline', 'link', 'image', 'video', 'clean'],[{ 'header': [1, 2, 3, 4, 5, 6, false] }] ]} />
                <TextInput source="strictly_necessary_cookies" label="Strictly necessary cookies Title" validate={ required() }  defaultValue="Strikt noodzakelijke cookies" />
                <RichTextInput {...props} source="strictly_necessary_cookies_description" toolbar={[ ['bold', 'italic', 'underline', 'link', 'image', 'video', 'clean'],[{ 'header': [1, 2, 3, 4, 5, 6, false] }] ]} />
                <TextInput source="analytics_cookies" label="Analytics Cookies Title" validate={ required() } defaultValue="Statistieken cookies" />
                <RichTextInput {...props} source="analytics_cookies_description" toolbar={[ ['bold', 'italic', 'underline', 'link', 'image', 'video', 'clean'],[{ 'header': [1, 2, 3, 4, 5, 6, false] }] ]} />
                <TextInput source="functional_cookies" label="Functional cookies title" validate={ required() } defaultValue="Functionele cookies" />
                <RichTextInput {...props} source="functional_cookies_description" toolbar={[ ['bold', 'italic', 'underline', 'link', 'image', 'video', 'clean'],[{ 'header': [1, 2, 3, 4, 5, 6, false] }] ]} />
                <TextInput source="marketing_cookies" label="Marketing cookies Title" validate={ required() } defaultValue="Advertentie cookies" />
                <RichTextInput {...props} source="marketing_cookies_description" toolbar={[ ['bold', 'italic', 'underline', 'link', 'image', 'video', 'clean'],[{ 'header': [1, 2, 3, 4, 5, 6, false] }] ]} />
            </FormTab>

            <FormTab label="More cookie information">

                <TextInput source="cookie_table_name_header" label="Cookie table name header" validate={ required() } />
                <TextInput source="cookie_table_category_header" label="Cookie table category header" validate={ required() } />
                <TextInput source="cookie_table_platform_header" label="Cookie table platform header" validate={ required() } />
                <TextInput source="cookie_table_period_header" label="Cookie table period header" validate={ required() } />
                <TextInput source="cookie_table_purpose_header" label="Cookie table purpose header" validate={ required() } />
                <TextInput source="cookie_table_unknown" label="Cookie table unknown" validate={ required() } />
                <TextInput source="cookie_table_updated" label="Cookie table updated" validate={ required() } />

                <TextInput source="more_cookie_information" label="Title" validate={ required() } />
                <RichTextInput {...props} source="more_cookie_information_description" toolbar={[ ['bold', 'italic', 'underline', 'link', 'image', 'video', 'clean'],[{ 'header': [1, 2, 3, 4, 5, 6, false] }] ]} defaultValue="" />
            </FormTab>
            <FormTab label="Buttons">
                <TextInput source="manage_cookie_preferences" label="Manage cookie preferences" validate={ required() } />
                <TextInput source="accept_cookies" label="Accept cookies" validate={ required() } />
                <TextInput source="cookies_settings" label="Cookie settings" validate={ required() } />
                <TextInput source="allow_all" label="Allow all" validate={ required() } />
                <TextInput source="reject" label="Reject" validate={ required() } />
                <TextInput source="save_settings" label="Save settings" validate={ required() } />
                <TextInput source="always_active" label="Always active" validate={ required() } />
            </FormTab>

            <FormTab label="Cookies">

                <ReferenceManyField
                    addLabel={false}
                    reference="cookie-consent-cookies"
                    target="cookieConsent"
                    perPage={25}
                    pagination={<Pagination />}
                >
                    <Datagrid>

                        <BooleanField source="customized" label="Customized" />
                        <TextField source="name" label="Name" />
                        <TextField source="customized_name" label="Custom name" />
                        <DateField source="update_date" sortBy="updateDate" showTime locales="nl-NL" />

                        <EditButton />

                    </Datagrid>
                </ReferenceManyField>

            </FormTab>

        </TabbedForm>

    </Edit>
);