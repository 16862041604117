// admin/src/Component/SpotActivator/List.js
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    Filter,
    TextInput,
    Pagination
} from 'react-admin';
import CloneSpotActivator from "./CloneSpotActivator";

const SpotActivatorFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search name" source="name" alwaysOn />
    </Filter>
);

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500]} {...props} />;

export const SpotActivatorList = (props) => (
    <List filters={<SpotActivatorFilter />} {...props} title="SpotActivators" perPage={500} pagination={<PostPagination />} sort={{ field: 'updateDate' , order: 'DESC' }}>
        <Datagrid>
            <TextField source="name" label="Name" validate="{ required() }" />
            <DateField source="update_date" sortBy="updateDate" showTime locales="nl-NL" />
            <TextField source="mbs_flight" label="Flight ID" />
            <EditButton />
            <CloneSpotActivator />
        </Datagrid>
    </List>
);