// admin/src/Component/CookieConsent/List.js
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField,
    NumberField,
    Filter,
    TextInput,
} from 'react-admin';
import CloneJobOffer from "./CloneJobOffer";

const JobFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search title" source="title" alwaysOn />
        <TextInput label="Search company" source="company" />
    </Filter>
);

export const JobList = (props) => (
    <List filters={<JobFilter />} {...props} title="Jobs" perPage={25} sort={{ field: 'updateDate' , order: 'DESC' }}>
        <Datagrid>

            <BooleanField source="active" label="Active" />
            <TextField source="title" label="Title" />
            <TextField source="company" label="Company" />
            <NumberField source="budget" label="Budget" />
            <DateField source="update_date" sortBy="updateDate" showTime locales="nl-NL"  />

            <CloneJobOffer />
            <EditButton />

        </Datagrid>
    </List>
);