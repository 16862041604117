// admin/src/Component/FeedConverter/List.js
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField
} from 'react-admin';
import DownloadFeedButton from './DownloadFeedButton';

export const FeedConverterList = (props) => (
    <List {...props} title="Feeds" perPage={25} sort={{ field: 'updateDate' , order: 'DESC' }}>
        <Datagrid>
            <BooleanField source="active" label="Active" />
            <TextField source="name" label="Name" validate="{ required() }" />
            <DateField source="update_date" sortBy="updateDate" showTime locales="nl-NL" />
            <EditButton />
            <DownloadFeedButton />
        </Datagrid>
    </List>
);