// admin/src/Component/TikTokConversion/Edit.js

import React from 'react';
import {
    regex,
    Edit,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    TextField
} from 'react-admin';


const validateUrl = regex(/^(ftp|http|https):\/\/[^ "]+$/, 'Must be a valid url');
const requiredUrl = [required(), validateUrl];

export const TikTokConversionEdit = (props) => {

    return (
    <Edit title="Edit tiktok conversion " { ...props }>
        <SimpleForm>

            <TextField source="id" />

            <BooleanInput source="active" label="Active" />
            <TextInput source="name" label="Name" validate={ required() } />
            <TextInput source="url" label="URL" validate={ requiredUrl } />
            <TextInput source="data_layer" label="Data layer" validate={ required() } />

            <ReferenceInput label="SameSite" source="cookie_consent_same_site" reference="cookie-consent-same-sites" validate={ required() }>
                <SelectInput optionText="name" />
            </ReferenceInput>

            <TextInput source="currency" label="Currency" defaultValue="EUR" validate={ required() } />
            <TextInput source="pixel_id" label="Pixel ID" validate={ required() } />
            <TextInput source="access_token" label="Access token" validate={ required() } />

        </SimpleForm>
    </Edit>
    );
};
