// admin/src/Component/SpotActivator/Edit.js

import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    regex,
    NumberInput,
    TextField
} from 'react-admin';

const validateEmail = regex(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const SpotActivatorEdit = (props) => {

    return (
    <Edit title="Edit SpotActivator" { ...props }>
        <SimpleForm>

            <TextField source="id" />

            <TextInput source="name" label="Name" validate={ required() } />

            <ReferenceInput label="SpotActivator Type" source="spot_activator_type" reference="spot-activator-types" sort={{ field: 'name', order: 'ASC' }} >
                <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput  perPage={1000} label="MBS flight" source="mbs_flight" reference="mbs-flights" validate={ required() } filterToQuery={views => ({ name: views })}>
                <AutocompleteInput optionText="name" translateChoice={false} />
            </ReferenceInput>

            <NumberInput source="price_per_grp" label="Price per GRP" step={1}  />
            <TextInput source="email" label="E-mail TV planner" validate={ validateEmail } />

        </SimpleForm>
    </Edit>
    );
};
