// in src/PinterestConversion/DownloadPITag.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import LanguageIcon from '@material-ui/icons/Language';
import config from 'react-global-configuration';

class DownloadPITag extends Component {

    handleClick = () => {

        const webUrl = config.get('app.web_url');
        const {record } = this.props;
        window.open(`${webUrl}pi-conversion-tag/${record.originId}.txt`, '_blank');
    }

    render() {
        return <Button variant="text" color="primary" size="small" onClick={this.handleClick}>
        <LanguageIcon /> &nbsp; Download PI tag
        </Button>;
    }
}

DownloadPITag.propTypes = {
    record: PropTypes.object,
};

export default connect(null, null)(DownloadPITag);