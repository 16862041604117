// admin/src/Component/ClockifySchedule/Edit.js

import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    DateInput
} from 'react-admin';


export const ClockifyScheduleEdit = (props) => {

    return (
    <Edit title="Edit clockify schedule " { ...props }>
        <SimpleForm>

            <TextInput source="name" label="Name" validate={ required() } />
            <DateInput source="start_date"  label="Start date" validate={ required() } />
            <DateInput source="end_date" label="End date" validate={ required() }/>

        </SimpleForm>
    </Edit>
    );
};
