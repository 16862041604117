import React from 'react';
import {
    Create,
    TabbedForm,
    FormTab,
    TextInput,
    BooleanInput,
    required,
    regex,
    ReferenceInput,
    SelectInput,
    NumberInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const validateUrl = regex(/^(ftp|http|https):\/\/[^ "]+$/, 'Must be a valid url');
const requiredUrl = [required(), validateUrl];

const validateEmail = regex(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Must be a valid e-mail');
const requiredEmail = [required(), validateEmail];

const postDefaultValue = {
    active: true,
    published: new Date(),
    publisher: '/api/job-offer-publishers/abovo-maxlead',
    country: '/api/job-offer-countries/NL'
};

export const JobCreate = (props) => (

    <Create { ...props }>
       <TabbedForm redirect="list" initialValues={postDefaultValue}>
           <FormTab label="Job description">

               <BooleanInput source="active" label="Active" />

               <TextInput source="title" label="Job title" validate={ required() } />
               <TextInput source="url" label="URL" validate={ requiredUrl } />
               <NumberInput source="budget" label="Budget" step={1}  />

               <RichTextInput {...props} source="description" toolbar={[ ['bold', 'italic', 'underline', 'link', 'clean'],[{ list: "bullet" }, { list: "ordered" }],[{ 'header': [1, 2, 3, 4, 5, 6, false] }] ]} validate={ required() }  />
           </FormTab>

           <FormTab label="Job contact information">

               <ReferenceInput label="Publisher" source="publisher" reference="job-offer-publishers" validate={ required() }>
                   <SelectInput optionText="name" />
               </ReferenceInput>

               <TextInput source="company" label="Company" validate={ required() } />
               <TextInput source="source_name" label="Parent company" />
               <TextInput source="email" label="Email" validate={ requiredEmail } />

               <ReferenceInput label="Country" source="country" reference="job-offer-countries" sort={{ field: 'name', order: 'ASC' }} >
                   <SelectInput optionText="name" />
               </ReferenceInput>

               <TextInput source="city" label="City" validate={ required() } />
               <TextInput source="state" label="State" />
               <TextInput source="postal_code" label="Postalcode" />
               <TextInput source="street_address" label="Street address" />

           </FormTab>

           <FormTab label="Job details">

               <ReferenceInput label="Job type" source="job_type" reference="job-offer-job-types">
                   <SelectInput optionText="name" />
               </ReferenceInput>

               <ReferenceInput label="Remote type" source="remote_type" reference="job-offer-remote-types">
                   <SelectInput optionText="name" />
               </ReferenceInput>

               <TextInput source="salary" label="Salary" />
               <TextInput source="education" label="Education" />
               <TextInput source="experience" label="Experience" />

               <TextInput source="category" label="Category" />
               <TextInput source="tracking_url" label="Tracking URL" />
               <TextInput source="metadata" label="Metadata" />
           </FormTab>

       </TabbedForm>
    </Create>
);