
import React from 'react';
import {
    Create,
    TabbedForm,
    FormTab,
    TextInput,
    required,
    regex,
    ReferenceInput,
    SelectInput,
    BooleanInput
} from 'react-admin';

const validateDomain = regex(/^[a-zA-Z0-9](?:[a-zA-Z0-9-.]{0,61}[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/, 'Must be a valid domain. Do not add www or http prefix. Subdomains should be converted to main domain.');
const requiredDomain = [required(), validateDomain];

export const CookieConsentScanCreate = (props) => (

    <Create { ...props }>
       <TabbedForm redirect="list">
           <FormTab label="Settings">

               <BooleanInput source="processed" label="Processed" defaultValue={false} />
               <BooleanInput source="passed" label="Passed" defaultValue={false} />
               <BooleanInput source="email_send" label="E-mail send" defaultValue={false} />
               <TextInput source="url" label="URL" validate={ requiredDomain } />

               <ReferenceInput label="Language" source="cookie_consent_scan_language" reference="cookie-consent-languages" validate={ required() }>
                   <SelectInput optionText="name" />
               </ReferenceInput>

           </FormTab>
           <FormTab label="Contact information">

               <TextInput source="first_name" label="First name" />
               <TextInput source="last_name" label="Last name" />
               <TextInput source="company" label="Company" />
               <TextInput label="Email Address" source="email" type="email" />
               <TextInput multiline source="comments" label="Comments" />

           </FormTab>

       </TabbedForm>
    </Create>
);