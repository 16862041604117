import React from 'react';

import {
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    regex,
} from 'react-admin';

export const CookieConsentCookieEdit = (props) => {

    const validateUrl = regex(/^(ftp|http|https):\/\/[^ "]+$/, 'Must be a valid url');
    const redirect = `/cookie-consents/`;

    return (
    <Edit {...props}>

        <SimpleForm redirect={redirect}>

                <TextInput source="name" label="Name" disabled/>
                <TextInput source="cookie" label="Cookie" disabled/>
                <TextInput source="domain" label="Domain" disabled/>
                <TextInput source="contents" label="Description" disabled/>

                <BooleanInput source="customized" label="Customized" default={true}/>

                <TextInput source="customized_name" label="Name"/>
                <TextInput source="customized_description" label="Description"/>
                <TextInput source="customized_platform" label="Platform"/>
                <TextInput source="customized_category" label="Category"/>
                <TextInput source="customized_period" label="Period"/>
                <TextInput source="customized_data_manager" label="Data manager"/>
                <TextInput source="customized_link" label="Link" validate={validateUrl}/>

        </SimpleForm>

    </Edit>
    );
};