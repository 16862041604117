// admin/src/Component/FacebookConversion/List.js
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    Filter,
    TextInput
} from 'react-admin';

import DownloadFBTag from "./DownloadFBTag";

const FacebookConversionFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search name" source="name" alwaysOn />
        <TextInput label="Search url" source="url" />
        <TextInput label="Search pixel ID" source="pixel_id" />
    </Filter>
);

export const FacebookConversionList = (props) => (
    <List filters={<FacebookConversionFilter />} {...props} title="Facebook conversions" perPage={25} sort={{ field: 'updateDate' , order: 'DESC' }}>
        <Datagrid>
            <TextField source="name" label="Name" />
            <TextField source="url" label="URL" />
            <TextField source="pixel_id" label="Pixel ID" />
            <DateField source="update_date" sortBy="updateDate" showTime locales="nl-NL" />
            <EditButton />
            <DownloadFBTag />
        </Datagrid>
    </List>
);