// admin/src/Component/FeedConverter/Edit.js

import React from 'react';
import {
    regex,
    Edit,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    SelectInput,
    BooleanInput
} from 'react-admin';


const validateUrl = regex(/^(ftp|http|https):\/\/[^ "]+$/, 'Must be a valid url');
const requiredUrl = [required(), validateUrl];

export const FeedConverterEdit = (props) => {

    return (
    <Edit title="Edit feed " { ...props }>
        <SimpleForm>

            <BooleanInput source="active" label="Active" />
            <BooleanInput source="auto_update" label="Auto update" />

            <TextInput source="name" label="Name" validate={ required() } />
            <TextInput source="website" label="Website" validate={ requiredUrl } />
            <TextInput multiline source="description" label="Description" validate={ required() }/>

            <ReferenceInput label="Feed input type" source="feed_input_type" reference="feed-input-types" sort={{ field: 'name' , order: 'ASC' }}>
                <SelectInput optionText="name"/>
            </ReferenceInput>

            <ReferenceInput label="Feed output type" source="feed_output_type" reference="feed-output-types" sort={{ field: 'name' , order: 'ASC' }}>
                <SelectInput optionText="name"/>
            </ReferenceInput>

            <TextInput source="source" label="Source" validate={ required() } />
            <TextInput source="output" label="Output" validate={ required() } />
            <TextInput source="currency" label="Currency" validate={ required() } />

        </SimpleForm>
    </Edit>
    );
};
