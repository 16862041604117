// admin/src/Component/SpotAnalyzer/List.js
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    Pagination,
    TextInput,
    Filter,
    ReferenceField,
    usePermissions,
    BooleanField
} from 'react-admin';
import RefreshSpotAnalyzer from './RefreshSpotAnalyzer';
import DownloadXlsx from './DownloadXlsx';
import CloneSpotAnalyzer from "./CloneSpotAnalyzer";
import authProvider from "../../authProvider";
import ViewDashboard from "./ViewDashboard";

const SpotAnalyzerFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search name" source="name" alwaysOn />
    </Filter>
);

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500]} {...props} />;

export const SpotAnalyzerList = (props) => {

    const {permissions} = usePermissions();

    return (
    <List filters={<SpotAnalyzerFilter/>} {...props} title="RTV" perPage={25} pagination={<PostPagination/>}
          sort={{field: 'updateDate', order: 'DESC'}}>
        <Datagrid>

            <BooleanField source="ready" label="Ready" />

            <TextField source="name" label="Name"/>
            <DateField source="update_date" sortBy="updateDate" showTime locales="nl-NL"/>

            <ReferenceField label="Type" source="spot_analyzer_type" reference="spot-analyzer-types">
                <TextField source="name"/>
            </ReferenceField>

            <EditButton/>

            <CloneSpotAnalyzer/>
            <ViewDashboard />

            {authProvider.isAuthorised(permissions, ['ROLE_ADMIN']) &&  <RefreshSpotAnalyzer/>}
            <DownloadXlsx/>

        </Datagrid>
    </List>);
};