// in src/SpotAnalyzer/CloneSpotActivator.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';

class CloneSpotActivator extends Component {

    handleClick = () => {
        let record = this.props.record;

        for (const property in record) {
            if(`${property}` === '@id' || `${property}` === '@type') {
                delete record[property];
            }
        }

        let source = encodeURIComponent(JSON.stringify(record));
        window.location.replace(`#/spot-activators/create?source=${source}`);
    }

    render() {
        return <Button variant="text" color="primary" size="small" onClick={this.handleClick}>
        <FileCopyIcon /> &nbsp; Copy
        </Button>;
    }
}

CloneSpotActivator.propTypes = {
    record: PropTypes.object,
};

export default connect(null, null)(CloneSpotActivator);