// in src/FacebookConversion/DownloadFBTag.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import LanguageIcon from '@material-ui/icons/Language';
import config from 'react-global-configuration';

class DownloadFBTag extends Component {

    handleClick = () => {

        const webUrl = config.get('app.web_url');
        const {record } = this.props;
        window.open(`${webUrl}fb-conversion-tag/${record.originId}.txt`, '_blank');
    }

    render() {
        return <Button variant="text" color="primary" size="small" onClick={this.handleClick}>
        <LanguageIcon /> &nbsp; Download FB tag
        </Button>;
    }
}

DownloadFBTag.propTypes = {
    record: PropTypes.object,
};

export default connect(null, null)(DownloadFBTag);