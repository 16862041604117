// src/authProvider.js
import config from 'react-global-configuration';

var jwt = require('jsonwebtoken');

// in src/authProvider.js
const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request(config.get('app.api_url') + 'login', {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(token => {
                localStorage.setItem('token', token.token);
            });
    },
    checkError: () => Promise.resolve(),
    checkAuth() {

        let isExpired = false;
        const token = localStorage.getItem('token');
        let decodedToken = jwt.decode(token, {complete: true});
        let dateNow = new Date();

        if(decodedToken === null || decodedToken.exp < dateNow.getTime()) {
            isExpired = true;
        }
        else {
            localStorage.setItem('permissions', decodedToken.payload.roles);
        }

         return isExpired ? Promise.reject({ redirectTo: '/login' }) : Promise.resolve();
    },
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    isAuthorised(roles, role) {

    let result = false;

    if(typeof roles !== 'undefined') {

        if (typeof (role) == 'object' && Array.isArray(role)) {

            let i;
            for (i = 0; i < role.length; i++) {

                if (roles.indexOf(role[i]) > -1) {
                    result = true;
                    break;
                }
            }
        } else {

            if (roles.indexOf(role) > -1) {
                result = true;
            }
        }
    }

    return result;
    }
};

export default authProvider;