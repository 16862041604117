// admin/src/Component/ClockifySchedule/Create.js

import React from 'react';
import {
    Create,
    SimpleForm,
    required,
    DateInput,
    TextInput
} from 'react-admin';

export const ClockifyScheduleCreate = (props) => {

    return (
    <Create title="Add schedule" { ...props }>
        <SimpleForm redirect="list">

            <TextInput source="name" label="Name" validate={ required() } />
            <DateInput source="start_date"  label="Start date" validate={ required() } />
            <DateInput source="end_date" label="End date" validate={ required() }/>

        </SimpleForm>
    </Create>
    );
};