// admin/src/Component/CookieConsentCookie/Create.js
import { parse } from "query-string";
import React from 'react';
import {
    regex,
    Create,
    SimpleForm,
    TextInput,
    required,
    BooleanInput
} from 'react-admin';

export const CookieConsentCookieCreate = (props) => {

    const {cookieConsent: consent_string} = parse(props.location.search);
    const landings_page = consent_string ? consent_string : '';
    const validateUrl = regex(/^(ftp|http|https):\/\/[^ "]+$/, 'Must be a valid url');
    const requiredUrl = [required(), validateUrl];

    const encoded_landings_page = encodeURIComponent(encodeURIComponent(landings_page));

    const redirect = encoded_landings_page ? `/cookie-consents/${encoded_landings_page}` : '/cookie-consents/';

    return (
<Create {...props}>
    <SimpleForm redirect={redirect} defaultValue={{cookieConsent: landings_page}}>

            <BooleanInput source="customized" label="Customized" defaultValue={true} disabled />

            <TextInput source="customized_name" label="Name" validate={required()}/>
            <TextInput source="customized_description" label="Description" validate={required()}/>
            <TextInput source="customized_platform" label="Platform" validate={required()}/>
            <TextInput source="customized_category" label="Category" validate={required()}/>
            <TextInput source="customized_period" label="Period" validate={required()}/>
            <TextInput source="customized_data_manager" label="Data manager" validate={required()}/>
            <TextInput source="customized_link" label="Link" validate={requiredUrl}/>

    </SimpleForm>
</Create>
    );
};