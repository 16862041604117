// admin/src/App.js

import React, {Component} from 'react';
import { Resource, Login } from 'react-admin';
import { Redirect, Route } from "react-router-dom";
import { HydraAdmin, hydraDataProvider as baseHydraDataProvider, fetchHydra as baseFetchHydra } from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import authProvider from "./authProvider";
import config from 'react-global-configuration';
import {FeedConverterCreate, FeedConverterEdit, FeedConverterList} from "./Components/FeedConverter";
import {CookieConsentCreate, CookieConsentEdit, CookieConsentList} from "./Components/CookieConsent";
import {CookieConsentScanCreate, CookieConsentScanEdit, CookieConsentScanList} from "./Components/CookieConsentScan";
import {SpotAnalyzerCreate, SpotAnalyzerEdit, SpotAnalyzerList} from "./Components/SpotAnalyzer";
import {SpotActivatorCreate, SpotActivatorEdit, SpotActivatorList} from "./Components/SpotActivator";
import {JobCreate, JobEdit, JobList } from "./Components/JobOffer";
import {FacebookConversionCreate, FacebookConversionEdit, FacebookConversionList} from "./Components/FacebookConversion";
import {EnhancedConversionCreate, EnhancedConversionEdit, EnhancedConversionList} from "./Components/EnhancedConversion";
import {TikTokConversionCreate, TikTokConversionEdit, TikTokConversionList} from "./Components/TikTokConversion";
import {PinterestConversionCreate, PinterestConversionEdit, PinterestConversionList} from "./Components/PinterestConversion";
import {ClockifyScheduleCreate, ClockifyScheduleEdit, ClockifyScheduleList} from "./Components/ClockifySchedule";
import MyLayout from "./Components/MyLayout";

import {CookieConsentCookieCreate, CookieConsentCookieEdit} from "./Components/CookieConsentCookies";
import { createTheme } from '@material-ui/core/styles';

const MyLoginPage = () => <Login backgroundImage="https://source.unsplash.com/1600x900/?data,tech" />;

const theme = createTheme({
    palette: {
        type: 'light'
    },
});

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const fetchHeaders = () => ({
    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
});
const fetchHydra = (url, options = {}) =>
    localStorage.getItem('token')
        ? baseFetchHydra(url, {
            ...options,
            headers: new Headers(fetchHeaders()),
        })
        : baseFetchHydra(url, options);
const apiDocumentationParser = (entrypoint) =>
    parseHydraDocumentation(
        entrypoint,
        localStorage.getItem('token')
            ? { headers: new Headers(fetchHeaders()) }
            : {}
    ).then(
        ({ api }) => ({ api }),
        (result) => {
            if (result.status === 401) {
                // Prevent infinite loop if the token is expired
                localStorage.removeItem('token');

                return Promise.resolve({
                    api: result.api,
                    customRoutes: [
                        <Route path="/" render={() => {
                            return localStorage.getItem('token') ? window.location.reload() : <Redirect to="/login" />
                        }} />
                    ],
                });
            }

            return Promise.reject(result);
        },
    );
const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser, false);

function waitForGlobal(name, timeout = 300) {
    return new Promise((resolve, reject) => {
        let waited = 0

        function wait(interval) {
            setTimeout(() => {
                waited += interval
                // some logic to check if script is loaded
                // usually it something global in window object
                if (window[name] !== undefined) {
                    return resolve()
                }
                if (waited >= timeout * 1000) {
                    return reject({ message: 'Timeout' })
                }
                wait(interval * 2)
            }, interval)
        }

        wait(30)
    })
}

export default class extends Component {
    state = { api: null };

    componentDidMount() {

        waitForGlobal('config').then(result => {

            config.set(window.config);

            apiDocumentationParser(config.get('app.api_url')).then(({api}) => {
                this.setState({api});
            }).catch((e) => {
                console.log(e);
            });
        });
    };

    render() {
        if (null === this.state.api) return <div>Loading...</div>;
        return (
            <HydraAdmin api={ this.state.api }
                   dataProvider= { dataProvider }
                   entrypoint={ entrypoint }
                   theme={ theme }
                   loginPage={MyLoginPage}
                   authProvider={ authProvider }
                   layout={MyLayout}
            >
                {permissions => [

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN','ROLE_JOBOFFER'])
                ? <Resource name="job-offers" list={ JobList } create={ JobCreate } edit={ JobEdit } options={{ label: "Job offers" }} />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN','ROLE_JOBOFFER'])
                ? <Resource name="job-offer-publishers" />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN','ROLE_JOBOFFER'])
                ? <Resource name="job-offer-countries" />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN','ROLE_JOBOFFER'])
                ? <Resource name="job-offer-job-types" />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN','ROLE_JOBOFFER'])
                ? <Resource name="job-offer-remote-types" />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN', 'ROLE_RTV'])
                ? <Resource name="spot-analyzers" list={ SpotAnalyzerList } create={ SpotAnalyzerCreate } edit={ SpotAnalyzerEdit } options={{ label: "RTV" }} />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN', 'ROLE_RTV'])
                ? <Resource name="analytics-views" />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN', 'ROLE_RTV'])
                ? <Resource name="care-campaign-names" />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN', 'ROLE_RTV'])
                ? <Resource name="target-audiences" />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN', 'ROLE_RTV'])
                ? <Resource name="countries" />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="feed-converters" list={ FeedConverterList } create={ FeedConverterCreate } edit={ FeedConverterEdit } options={{ label:"Feeds" }} />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN','ROLE_CONSENT'])
                ? <Resource name="cookie-consents" list={ CookieConsentList } create={ CookieConsentCreate } edit={ CookieConsentEdit } options={{ label: "Cookie Consents" }} />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN','ROLE_CONSENT'])
                ? <Resource name="cookie-consent-scans" list={ CookieConsentScanList } create={ CookieConsentScanCreate } edit={ CookieConsentScanEdit } options={{ label: "Cookie Consent Scans" }} />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN','ROLE_CONSENT'])
                ? <Resource name="cookie-consent-cookies" create={ CookieConsentCookieCreate } edit={ CookieConsentCookieEdit } options={{ label: "Cookie Consent Cookies" }} />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="spot-activators" list={ SpotActivatorList } create={ SpotActivatorCreate } edit={ SpotActivatorEdit } options={{ label: "SpotActivator" }} />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="facebook-conversions" list={ FacebookConversionList } create={ FacebookConversionCreate } edit={ FacebookConversionEdit } options={{ label: "Facebook conversion" }} />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="enhanced-conversions" list={ EnhancedConversionList } create={ EnhancedConversionCreate } edit={ EnhancedConversionEdit } options={{ label: "Enhanced conversion" }} />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="tik-tok-conversions" list={ TikTokConversionList } create={ TikTokConversionCreate } edit={ TikTokConversionEdit } options={{ label: "TikTok conversion" }} />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="pinterest-conversions" list={ PinterestConversionList } create={ PinterestConversionCreate } edit={ PinterestConversionEdit } options={{ label: "Pinterest conversion" }} />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="clockify-schedules" list={ ClockifyScheduleList } create={ ClockifyScheduleCreate } edit={ ClockifyScheduleEdit } options={{ label: "Clockify schedule" }} />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN','ROLE_CONSENT'])
                ? <Resource name="cookie-consent-templates" />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN','ROLE_CONSENT'])
                ? <Resource name="cookie-consent-languages" />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN','ROLE_CONSENT'])
                ? <Resource name="cookie-consent-positions" />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN','ROLE_CONSENT'])
                ? <Resource name="cookie-consent-same-sites" />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN','ROLE_CONSENT'])
                ? <Resource name="cookie-consent-scan-cookies" />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="adform-advertisers" />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="facebook-advertisers" />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="feed-input-types" />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="feed-output-types" />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="mbs-flights" />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="mbs-target-audiences" />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN','ROLE_CONSENT'])
                ? <Resource name="cookie-consent-software" />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="spot-activator-types" />: null,

                authProvider.isAuthorised(permissions, ['ROLE_ADMIN', 'ROLE_RTV'])
                ? <Resource name="spot-analyzer-types" />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="weather-stations" />: null,

                authProvider.isAuthorised(permissions, 'ROLE_ADMIN')
                ? <Resource name="clockify-clients" />: null

                ]}
            </HydraAdmin>
        )
    }
}

