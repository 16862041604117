// in src/SpotAnalyzer/DownloadGoogleDataStudioTag.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import config from 'react-global-configuration';

class DownloadXlsx extends Component {

    handleClick = () => {

        const webUrl = config.get('app.web_url');
        const {record } = this.props;
        window.open(`${webUrl}spot-analyzer/raw-files/${record.originId}.xlsx`, '_blank');
    }

    render() {

        const {record } = this.props;

        if (record.ready) {

            return <Button variant="text" color="primary" size="small" onClick={this.handleClick}>
                <CloudDownloadIcon/> &nbsp; XLSX
            </Button>;
        }
        else {
            return null;
        }
    }
}

DownloadXlsx.propTypes = {
    record: PropTypes.object,
};

export default connect(null, null)(DownloadXlsx);