// in src/Components/SpotAnalyzer/ViewDashboard.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';

class ViewDashboard extends Component {

    handleClick = () => {
        const {record} = this.props;

        window.open(`${record.dashboard_url}`, '_blank');
    }

    render() {

        const {record} = this.props;

        if (record.dashboard_url) {

            return <Button variant="text" color="primary" size="small" onClick={this.handleClick}>
                <VisibilityIcon/> &nbsp; Dashboard
            </Button>;
        } else {
            return null;
        }
    }
}

ViewDashboard.propTypes = {
    record: PropTypes.object,
};

export default connect(null, null)(ViewDashboard);