// admin/src/Component/ClockifySchedule/List.js
import React from 'react';
import {
    List,
    Datagrid,
    DateField,
    TextField,
    EditButton,
    BooleanField,
    usePermissions
} from 'react-admin';
import DownloadCsv from './DownloadCsv';
import RefreshClockify from './RefreshClockify';
import authProvider from "../../authProvider";

export const ClockifyScheduleList = (props) => {

    const {permissions} = usePermissions();

return (
    <List {...props} title="Clockify Schedule" perPage={25} sort={{ field: 'name' , order: 'ASC' }}>
        <Datagrid>

            <BooleanField source="ready" label="Ready" />

            <TextField source="name" label="Name" />
            <DateField source="start_date" label="Start date" />
            <DateField source="end_date" label="End date" />
            <EditButton />

            {authProvider.isAuthorised(permissions, ['ROLE_ADMIN']) &&  <RefreshClockify />}
            <DownloadCsv/>

        </Datagrid>
    </List>);
};