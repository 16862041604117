// admin/src/Component/CookieConsent/List.js
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField,
    NumberField,
    ReferenceField,
    Filter,
    TextInput,
    BooleanInput
} from 'react-admin';

import ShowReport from "./ShowReport";
import LocalUrlField from "./LocalUrlField";

const ConsentFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search url" source="url" alwaysOn />
        <TextInput label="Search company" source="company" />
        <BooleanInput source="processed" label="Processed" defaultValue={false} />
        <BooleanInput source="passed" label="Passed" defaultValue={false} />
        <BooleanInput source="email_send" label="E-mail send" defaultValue={false} />
        <BooleanInput source="gtm_installed" label="GTM installed" defaultValue={false} />
    </Filter>
);

export const CookieConsentScanList = (props) => (
    <List filters={<ConsentFilter />} {...props} title="Cookie consent scans" perPage={25} sort={{ field: 'updateDate' , order: 'DESC' }}>
        <Datagrid>

            <BooleanField source="processed" label="Processed" />
            <BooleanField source="passed" label="Passed" />
            <BooleanField source="gtm_installed" label="GTM installed" />
            <NumberField source="number_of_scanned_pages" sortBy="numberOfScannedPages" label="Pages" />
            <LocalUrlField source="url" label="URL" />
            <DateField source="update_date" sortBy="updateDate" showTime locales="nl-NL"  />

            <ReferenceField label="Software" source="cookie_consent_software" reference="cookie-consent-software">
                <TextField source="name" />
            </ReferenceField>

            <EditButton />
            <ShowReport />

        </Datagrid>
    </List>
);