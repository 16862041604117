import React from 'react';

import {
    Edit,
    TabbedForm,
    FormTab,
    TextInput,
    BooleanInput,
    required,
    regex,
    ReferenceInput,
    SelectInput,
    NumberInput,
    ReferenceManyField,
    TextField,
    Datagrid,
    DateField,
    Pagination
} from 'react-admin';

const validateDomain = regex(/^[a-zA-Z0-9](?:[a-zA-Z0-9-.]{0,61}[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/, 'Must be a valid domain. Do not add www or http prefix. Subdomains should be converted to main domain.');
const requiredDomain = [required(), validateDomain];

export const CookieConsentScanEdit = (props) => (
    <Edit {...props}>

        <TabbedForm>

            <FormTab label="Settings">

                <BooleanInput source="processed" label="Processed" />
                <BooleanInput source="passed" label="Passed" />
                <BooleanInput source="email_send" label="E-mail send" />
                <BooleanInput source="gtm_installed" label="GTM installed" />
                <BooleanInput source="google_consent_mode_enabled" label="Google Consent Mode enabled" />
                <NumberInput source="number_of_scanned_pages" label="Number of scanned pages" />

                <TextInput source="url" label="URL" validate={ requiredDomain } />

                <ReferenceInput label="Language" source="cookie_consent_scan_language" reference="cookie-consent-languages" validate={ required() }>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <ReferenceInput label="Current consent software" source="cookie_consent_software" reference="cookie-consent-software">
                    <SelectInput optionText="name" />
                </ReferenceInput>

            </FormTab>
            <FormTab label="Contact information">

                <TextInput source="first_name" label="First name" />
                <TextInput source="last_name" label="Last name" />
                <TextInput source="company" label="Company" />
                <TextInput label="Email Address" source="email" type="email" />
                <TextInput multiline source="comments" label="Comments" />

            </FormTab>

            <FormTab label="Cookies">

                <ReferenceManyField
                    addLabel={false}
                    reference="cookie-consent-scan-cookies"
                    target="cookieConsentScan"
                    perPage={25}
                    pagination={<Pagination />}
                >
                    <Datagrid>

                        <TextField source="name" label="Name" />
                        <TextField source="domain" label="Domain" />
                        <DateField source="update_date" sortBy="updateDate" showTime locales="nl-NL" />

                    </Datagrid>
                </ReferenceManyField>

            </FormTab>

        </TabbedForm>

    </Edit>
);