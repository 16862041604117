// admin/src/Component/FacebookConversion/Create.js

import React from 'react';
import {
    regex,
    Create,
    SimpleForm,
    TextInput,
    required,
    SelectInput,
    BooleanInput,
    ReferenceInput
} from 'react-admin';

const validateUrl = regex(/^(ftp|http|https):\/\/[^ "]+$/, 'Must be a valid url');
const requiredUrl = [required(), validateUrl];

const facebookConversionDefaultValue = {
    cookie_consent_same_site: '/api/cookie-consent-same-sites/08fc2129-d301-4b6a-a7bb-22a3f7a65897',
};


export const FacebookConversionCreate = (props) => {

    return (
    <Create title="Add facebook conversion " { ...props }>
        <SimpleForm redirect="list" initialValues={facebookConversionDefaultValue} >

            <BooleanInput source="active" label="Active" defaultValue={true} />
            <BooleanInput source="facebook_enabled" label="Also throw facebook pixel" defaultValue={true} />
            <TextInput source="name" label="Name" validate={ required() } />
            <TextInput source="url" label="URL" validate={ requiredUrl } />
            <TextInput source="data_layer" label="Data layer" validate={ required() } defaultValue="dataLayer" />

            <ReferenceInput label="SameSite" source="cookie_consent_same_site" reference="cookie-consent-same-sites" validate={ required() }>
                <SelectInput optionText="name" />
            </ReferenceInput>

            <TextInput source="currency" label="Currency" defaultValue="EUR" validate={ required() } />
            <TextInput source="pixel_id" label="Pixel ID" validate={ required() } />
            <TextInput source="access_token" label="Access token" validate={ required() } />

        </SimpleForm>
    </Create>
    );
};