// admin/src/Component/CookieConsent/List.js
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField,
    NumberField,
    Filter,
    TextInput,
    ReferenceField
} from 'react-admin';

import DownloadGTMTag from './DownloadGTMTag';
import CloneCookieConsent from "./CloneCookieConsent";
import LocalUrlField from "../CookieConsentScan/LocalUrlField";

const ConsentFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search name" source="name" alwaysOn />
        <TextInput label="Search url" source="url" />
    </Filter>
);

export const CookieConsentList = (props) => (
    <List filters={<ConsentFilter />} {...props} title="Cookie consent" perPage={25} sort={{ field: 'updateDate' , order: 'DESC' }}>
        <Datagrid>

            <BooleanField source="active" label="Active" />
            <NumberField source="number_of_scanned_pages" sortBy="numberOfScannedPages" label="Pages" />
            <TextField source="name" label="Name" />

            <ReferenceField label="Template" source="cookie_consent_template" reference="cookie-consent-templates">
                <TextField source="name" />
            </ReferenceField>

            <LocalUrlField source="url" label="URL" />
            <DateField source="update_date" sortBy="updateDate" showTime locales="nl-NL"  />
            <EditButton />
            <CloneCookieConsent />
            <DownloadGTMTag />

        </Datagrid>
    </List>
);