// in src/SpotAnalyzer/DownloadGoogleDataStudioTag.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import config from 'react-global-configuration';

class DownloadCsv extends Component {

    handleClick = () => {

        const webUrl = config.get('app.web_url');
        const {record } = this.props;
        window.open(`${webUrl}clockify/raw-files/${record.originId}.csv`, '_blank');
    }

    render() {

        const {record } = this.props;

        if (record.ready) {

            return <Button variant="text" color="primary" size="small" onClick={this.handleClick}>
                <CloudDownloadIcon/> &nbsp; CSV
            </Button>;
        }
        else {
            return null;
        }
    }
}

DownloadCsv.propTypes = {
    record: PropTypes.object,
};

export default connect(null, null)(DownloadCsv);