// admin/src/Component/EnhancedConversion/List.js
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    Filter,
    TextInput
} from 'react-admin';

const EnhancedConversionFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search name" source="name" alwaysOn />
        <TextInput label="Search url" source="url" />
    </Filter>
);

export const EnhancedConversionList = (props) => (
    <List filters={<EnhancedConversionFilter />} {...props} title="Enhanced conversions" perPage={25} sort={{ field: 'updateDate' , order: 'DESC' }}>
        <Datagrid>
            <TextField source="name" label="Name" />
            <TextField source="url" label="URL" />
            <DateField source="update_date" sortBy="updateDate" showTime locales="nl-NL" />
            <EditButton />
        </Datagrid>
    </List>
);